<template>
    <div class="pageContainer app-container studentContainer eventBox" style="position: relative'">
        <el-tabs v-model="activeName" type="card" style="" @tab-click="changeTab">
            <el-tab-pane label="" name="td">
                <span slot="label">
                    <i>编辑优秀实习生</i>
                </span>
            </el-tab-pane>
        </el-tabs>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="学生姓名" prop="stu_name">
                <el-input v-model="ruleForm.stu_name" size="small"></el-input>
            </el-form-item>

            <el-form-item label="照片" prop="avatar">
                <el-upload action="" :http-request="uploadOss" :on-success="uploadFiles" :show-file-list="false"
                    name="image">
                    <div class="tzsbox">
                        <img v-if="ruleForm.avatar" :src="ruleForm.avatar" class="avatar"
                            style="max-width: 100%;max-height: 100%;">
                        <div v-else style="width: 100px; height: 100px; font-size: 100px;  color: #ccc; ">
                            <i class="el-icon-plus"></i>
                        </div>
                    </div>
                </el-upload>

                <!--          <div v-if="ruleForm.avatar" :style="{backgroundImage:'url('+ruleForm.avatar+')'}" class="tzsbox">-->
                <!--            <img :src="ruleForm.avatar" v-if="ruleForm.avatar" style="width: 100%; height: 100%; opacity: 0;"-->
                <!--                 v-viewer />-->
                <!--          </div>-->
            </el-form-item>



            <el-form-item label="联系电话" prop="phone">
                <el-input v-model="ruleForm.phone" size="small"></el-input>
            </el-form-item>

            <el-form-item label="性别" prop="sex">
                <!-- <el-input v-model="" size="small"></el-input> -->
                <el-radio-group v-model="ruleForm.sex" size="small">
                    <el-radio :label="2">女</el-radio>
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="0">未知</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="年级名称" prop="grade_name">
                <el-input v-model="ruleForm.grade_name" size="small"></el-input>
            </el-form-item>
            <el-form-item label="系部名称" prop="departments_name">
                <el-input v-model="ruleForm.departments_name" size="small"></el-input>
            </el-form-item>
            <el-form-item label="专业名称" prop="major_name">
                <el-input v-model="ruleForm.major_name" size="small"></el-input>
            </el-form-item>
            <el-form-item label="入学年份" prop="start_year">
                <el-input v-model="ruleForm.start_year" size="small"></el-input>
            </el-form-item>
            <el-form-item label="就业单位" prop="enter">
                <el-input v-model="ruleForm.enter" size="small"></el-input>
            </el-form-item>
            <el-form-item label="优秀事迹">
                <div style="line-height: initial">
                    <UEditor ref="ue" @ready="editorReady" style="width: 100%"></UEditor>
                </div>
            </el-form-item>
            <el-form-item label="备注" prop="remarks">
                <el-input v-model="ruleForm.remarks" size="small"></el-input>
            </el-form-item>
            <el-form-item label="排序号" prop="sort">
                <el-input-number size="small" v-model="ruleForm.sort" :min="1" :max="100"></el-input-number>
            </el-form-item>
            <el-form-item>


            </el-form-item>
        </el-form>
        <div class="flexStart" style="position:fixed;bottom:12px;z-index:1000;background:#fff;width:100%;padding:20px;">
            <el-button type="primary" @click="submitForm('ruleForm')">保存
            </el-button>
            <el-button type="warning" plain @click="resetForm('ruleForm')">重置</el-button>
            <el-button type="primary" plain @click="goBack('ruleForm')">返回</el-button>
        </div>
        <div style="width:100%;height:50px;"></div>
    </div>
</template>

<script>
import UEditor from "../../notify/ueditor.vue";

export default {
    components: {
        UEditor,
    },
    data() {
        return {
            editor: null,
            dialogVisible: false,
            count: 0,
            current_page: 1,
            per_page: 13,
            activeName: "td",
            dataList: [],
            showSP: false,
            formData: {},
            spword: "",
            showPrint: false,
            ruleForm: {
                id: "",
                create_by: "",
                enter: "",
                event_desc: "",
                remarks: "",
                stu_name: "",
                sort: 1,
                phone: "",
                sex: 0,
                grade_name: "",
                class_name: "",
                departments_name: "",
                major_name: "",
                start_year: "",
                avatar: '',
            },
            rules: {
                stu_name: [
                    { required: true, message: "请输入学生姓名", trigger: "change" },
                ],
                event_desc: [
                    { required: true, message: "请选择输入优秀事迹", trigger: "change" },
                ],
            },

            props: { emitPath: false, value: "id", label: "stu_name" },
            studentsTree: [],
        };
    },
    mounted() {
        console.log(sessionStorage.getItem("auth"));
        // this.getlist();
        // this.getStudentsTree();
        let param = this.$route.query.param;
        console.log(141, param)
        if (param) {
            param = JSON.parse(decodeURIComponent(param))
            console.log(1111122222,param)
            this.findOne(param)
        }
    },
    methods: {
        uploadFiles(e) {
            let _this = this
            this.ruleForm.avatar = e.src
            this.ruleForm = { ..._this.ruleForm }
            console.log(301, this.ruleForm.avatar)

        },
        getlist() {
            this.$http.post("/api/by_stu_event_list", {
                page: this.current_page,
                pagesize: this.per_page,
                stu_name: this.formData.stu_name,
            })
                .then((res) => {
                    console.log(res);
                    this.dataList = res.data.data;
                    this.count = res.data.page.count;
                    this.current_page = res.data.page.current_page;
                    this.per_page = res.data.page.per_page;
                });
        },
        editorReady(editorInstance) {
            editorInstance.setContent(this.ruleForm.event_desc);
            editorInstance.addListener("contentChange", () => {
                this.ruleForm.event_desc = editorInstance.getContent();
            });
            this.editor = editorInstance;
        },
        getStudentsTree() {
            this.$http.post("/api/by_students_tree").then((res) => {
                if (res.data && res.data.length > 0) {
                    this.studentsTree = res.data;
                }
            });
        },

        changeTab() {
            this.getlist();
        },
        onSearch() {
            this.getlist();
        },
        onReset() {
            this.formData = {};
            this.getlist();
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.current_page = val;
            this.getlist();
        },
        submitForm(formName) {
            console.log(246)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$http.post("/api/by_stu_event_edit", this.ruleForm)
                        .then((res) => {
                            // this.dialogVisible = false;
                            // this.getlist();
                            this.$router.push("/bygz_event")
                        });
                } else {
                    console.log("保存失败!");
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            let _this = this;
            setTimeout(() => {
                _this.editor.setContent("");
            }, 300);
        },
        findOne(e) {
            this.ruleForm.id = e.id;
            this.ruleForm.create_by = e.create_by;
            this.ruleForm.enter = e.enter;
            this.ruleForm.event_desc = e.event_desc;
            this.ruleForm.remarks = e.remarks;
            this.ruleForm.sort = e.sort;
            this.ruleForm.stu_name = e.stu_name;
            this.ruleForm.phone = e.phone;
            this.ruleForm.sex = e.sex;
            this.ruleForm.grade_name = e.grade_name;
            this.ruleForm.class_name = e.class_name;
            this.ruleForm.departments_name = e.departments_name;
            this.ruleForm.major_name = e.major_name;
            this.ruleForm.start_year = e.start_year;
            this.ruleForm.avatar = e.avatar;
            this.dialogVisible = true;
            let _this = this;
            setTimeout(() => {
                _this.editor.setContent(e.event_desc);
            }, 300);
        },
        saveInfo(e) {
            this.ruleForm = {
                id: "",
                create_by: "",
                enter: "",
                event_desc: "",
                remarks: "",
                stu_name: "",
                sort: 1,
                phone: "",
                sex: 0,
                grade_name: "",
                class_name: "",
                departments_name: "",
                major_name: "",
                start_year: "",
                avatar: ''
            };
            this.dialogVisible = true;
        },
        goBack() {
            this.$router.push("/bygz_event")
        },
        delectMessage(e) {
            this.$http.post("/api/by_stu_event_delete", {
                id: e.id,
            })
                .then((res) => {
                    this.getlist();
                });
        },
        handleCancel() { },
        printIframe() {
            var iframe = document.getElementById("printPage");
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
        },
    },
};
</script>
<style lang="less" type="text/less">
.ueditor-toolbar-container {
    z-index: 999999999;
    /* 设置一个高于el-dialog的z-index值 */
}

.tzsbox {
    width: 300px;
    border: 1px dotted #ccc;
    height: 300px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
}

.eventBox {
  background-color: #fff;
}
</style>
<style lang="less" type="text/less" scoped>
.v-modal {
    z-index: 9
}
</style>